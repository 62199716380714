import React, {useState} from "react";
import Loadable from "react-loadable";
import withRoleRequired from '../../lib/withRoleRequired';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Spinner from "../../components/deliberative-survey/Spinner";

const LoadableImpactChart = Loadable({
  loader: () => import("../../components/deliberative-survey/ImpactChart"),
  loading() {
    return (
      <>
        <div className='col-span-7'/>
        <div className='col-span-7'/>
        <div className='m-auto col-span-7'>
          <Spinner/>
        </div>
      </>
    );
  }
});


const Interest: React.FunctionComponent = () => {
  const [selectedView, setSelectedView] = useState<string>('Size of Impact');

  return (
    <Layout pageHeader="Impact of Future Factors"
      breadcrumbs={[{ label: 'Attitudes Towards Defence', link: '/deliberative-survey' }]}
      moreInfo = "deliberative-survey/impact-future-factors"
    >
      <SEO
        keywords={[`future`, `workforce`, `modelling`, `tool`]}
        title="Attitudes Towards Defence Impact of Future Factors"
      />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 h-full">
        <div className = "grid grid-cols-9 grid-flow-row h-full grid-rows-impact w-full" >
          <div className = 'col-span-9 pb-4'>
            {(selectedView === "Size of Impact")?
              "This set of graphs allows you to see the change in people’s propensity to follow a career in Defence based on the introduction of various future factors. The responses can be viewed in relation to the type of factor considered, whether they were provided by the young adult group, parents or careers advisors and whether they apply to Regular Armed Forces, Reserves or Civil Service. Use the radio buttons to select the view you would like to see and hover over the bars themselves to see the full version of the statement about the future factor."
              :
              "This set of visualisations highlights where there were population differences in the changes in propensity to work for Defence based on the introduction of future factors. The highlighted icons indicate which population features the differences were based on. Hovering over the icons provides a full explanation of where the difference lies. Use the radio buttons to select the view you would like to see, based on the type of factor, whether provided by the young adult group or parents and the part of Defence to which they apply."
            }
          </div>
          <LoadableImpactChart selectedView={selectedView} setSelectedView={setSelectedView}/>
        </div>
      </div>

    </Layout>
  );
}

export default withRoleRequired(Interest);
